
.leftAlign span {
    justify-content: left;
    text-align: left;
}

.leftAlign div {
    justify-content: left;
    text-align: left;
}

.leftAlign {
    padding-left: 0px
}

.leftPadding {
    padding-left: 15px
}

tbody tr.MuiTableRow-root:nth-child(even) {
    background-color: #dddddd;
}
tbody tr.MuiTableRow-root:nth-child(odd) {
    background-color: #eeeeee;
}

.chip-overflow span {
    overflow: visible;
}

.status-chip span {
    margin-right: 5px;
    margin-left: 5px;
}

.dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 3px;
    margin-left: 3px
}